<!--
 * @Author: John
 * @Date: 2022-02-22 11:45:19
 * @LastEditTime: 2022-03-08 15:29:27
 * @LastEditors: John
 * @Description: 更新提醒邮箱
 * @FilePath: \jjms_ui\src\views\func\addRemind.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <el-form :model="userInfo" :rules="formRule" ref="updateUserForm" label-width="100px">
            <el-form-item label="提醒邮箱" prop="email">
                <el-input v-model="userInfo.email" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit">更新</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import api from '@/api/req_api.js'

export default {
    name: 'remind',  // 更新提醒邮箱
    components: {
        myBreadcrumb
    },
    data() {
        let validateEmail = (rule, value, callback) => {
            let emailReg = /\w+@\w+\.(com|co|cn|org)/g;
            if(!emailReg.test(value)) {
                callback(new Error('请输入合法的邮箱地址!'));
                return;
            }
            callback();
        };
        return {
            breadcrumbData: [
                {title: '功能设置'},
                {title: '提醒邮箱', url: '/main/func-add-remind'}
            ],
            userInfo: {
                email: ''
            },
            formRule: {
                email: [
                    {required: true, message: '提醒邮箱是必填项!', trigger: 'blur'},
                    {validator: validateEmail, trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        loadData() {
            if(this.$store.state.user) {   // 如果本地缓存的用户数据存在使用本地缓存数据
                this.userInfo = this.$store.state.user;  
                return;
            }
            api.getUserInfo()   // 请求当前用户数据
                .then(data => {
                    if(data != null) {
                        this.userInfo = data;   
                    }
                })
        },
        submit() {
            this.$refs['updateUserForm'].validate(valid => {
                if(valid) {
                    api.updateUserInfo(this.userInfo)
                        .then(data => {
                            if(data != null) {
                                this.$message.success('更新成功！');
                                this.$store.commit('modify', this.userInfo);
                            }
                        })
                }
            });
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>
